export default [
  {
    title: "Dashboard",
    route: "dashboard",
    icon: "HomeIcon",
  },
  {
    title: "Order",
    route: "hypermarket-order",
    icon: "ShoppingCartIcon",
    children: [
      {
        title: "B2BPro Orders",
        route: "hypermarket-order",
        icon: "ShoppingBagIcon",
      },
      {
        title: "Freight Forwarding",
        route: "fleet-forwarding",
        icon: "SendIcon",
      },
      {
        title: "Chartered Truck",
        route: "chartered-truck",
        icon: "TruckIcon",
      },
    ],
  },

  {
    title: "My Deliveries",
    icon: "AlignJustifyIcon",
    children: [
      {
        title: "Deliveries",
        route: "MyDeliveries",
        icon: "PackageIcon",
      },
      {
        title: "Pending Deliveries",
        route: "PendingDeliveries",
        icon: "LayersIcon",
      },
      {
        title: "Cancellation Requests",
        route: "CancellationRequests",
        icon: "RepeatIcon",
      },
    ],
  },
  {
    title: "My Vehicle",
    route: "myvehicle",
    icon: "AnchorIcon",
  },
  {
    title: "My Driver",
    route: "mydriver",
    icon: "UsersIcon",
  },
  {
    title: "Delivery History",
    route: "DeliveryHistory",
    icon: "BookOpenIcon",
  },
  {
    title: "Track & Trace",
    route: "track",
    icon: "GitPullRequestIcon",
  },
];
